import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Signup/Signup";
import Home from "../Pages/Home/Home";
import Forgot from "../Pages/ForgotPassword/Forgot";
import Verify from "../Pages/VerifyAccount/Verify";
import SetForgotPassword from "../Pages/ForgotPassword/SetForgotPassword";



// Create the router configuration
const router = createBrowserRouter([
  {
    path: "/signup",
    element: <Signup />,
    index: true,
  },
  {
    path: "/login",
    element: <Login />,
    index: true,
  },

  {
    path: "/Forgot-Password",
    element: <Forgot />,
    index: true,
  },

  {
    path: "/Verify-Account",
    element: <Verify />,
    index: true,
  },
  {
    path: "/set-forgot-password",
    element: <SetForgotPassword />,
    index: true,
  },
  {
    path: "/",
    element: <Home />,
    index: true,
  },

  {
    path: "*",
    element: <p>404 Error - Nothing here...</p>,
  },
]);

export default router;
