import React, { useState } from "react";
import Cookies from "js-cookie";
import "./Navbar.css";
import logo from "../../../assets/Logo.png";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  exportPdf,
  getNotes,
  searchNotes,
  toggleExpand,
} from "../../../store/home/homeSlice";
import { Logout } from "../../../store/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userEmail = Cookies.get("user-email");
  const expanded = useSelector((state) => state.home.isSideBarExpanded);
  const { isLoggedIn, AccessToken } = useSelector((state) => state.auth);
  const { pdfExporting } = useSelector((state) => state.home);
  const [query, setQuery] = useState("");
  const SidebarExpandHandler = () => {
    dispatch(toggleExpand());
  };
  const logoutHandler = async () => {
    dispatch(Logout());
    setTimeout(() => {
      navigate("/login");
    }, 3000);
    toast.success("Logout successful", {
      className: "custom-toast",
    });
  };
  const queryOnChangeHandler = (e) => {
    setQuery(e.target.value);
  };
  const downloadPdf = (e) => {
    e.preventDefault();
    const url = ` https://52.91.8.30/pdfReportGen/Personal-${userEmail}`;
    window.open(url, "_blank");
  };
  const querySubmitHandler = (e) => {
    e.preventDefault();
    if (query.trim() === "") {
      const payload = {
        accesstoken: AccessToken,
        workspaceId: 1,
        skip: 0,
        limit: 10,
      };
      dispatch(getNotes(payload));
    } else dispatch(searchNotes({ accesstoken: AccessToken, query }));
  };
  return (
    <>
      <div className="mobile-nav">
        <div className="mobile-nav-logo">
          <img src={logo} alt="logo pic" />
          <p>&nbsp;BlueNote</p>
        </div>
        <div className="mobile-nav-right">
          <i class="fa-solid fa-toggle-on"></i>
          <button onClick={logoutHandler}>Logout</button>
        </div>
      </div>
      <div className="home-right-navbar">
        <div className="toggle-search">
          {expanded ? (
            <i onClick={SidebarExpandHandler} class="fa-solid fa-xmark"></i>
          ) : (
            <i onClick={SidebarExpandHandler} class="fa-solid fa-bars"></i>
          )}
          <form onSubmit={querySubmitHandler} className="navbar-search">
            
            <input
              value={query}
              onChange={queryOnChangeHandler}
              type="text"
              placeholder="Search a keyword"
            />
            <button type="submit">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </form>
        </div>
        <div className="nav-right">
          <button onClick={downloadPdf} disabled={pdfExporting === true}>
            {pdfExporting ? (
              <Bars
                height="15"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{ margin: "0", padding: "0" }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <span>
                {" "}
                PDF <i class="fa-solid fa-download"></i>
              </span>
            )}
          </button>
          <button onClick={logoutHandler}>
            Logout <i class="fa-solid fa-arrow-right-from-bracket"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
