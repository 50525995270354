import React, { useEffect } from "react";
import "./Sidebar.css";
import logo from "../../../assets/Logo.png";
import leaf from "../../../assets/leaf.jpg";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const expanded = useSelector((store) => store.home.isSideBarExpanded);
  useEffect(() => {
    if (expanded)
      document.getElementById("sidebar-mobile").style.width = "50vw";
    else {
      document.getElementById("sidebar-mobile").style.width = "0vw";
    }
  }, [expanded]);

  return (
    <div className="sidebar">
      <div className="sidebar-web">
        <div className="sidebar-logo">
          <img src={logo} alt="logo pic" />
          <p>&nbsp;BlueNote</p>
        </div>
        <hr />
        <div className="sidebar-content">
          <div className="sidebar-tabs">
            <div className="active-sidebar-tab">
            <i class="fa-regular fa-note-sticky"></i>
              <span>Notes & Highlights</span>
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/l%C9%98af-technologies/",
                  "_blank"
                );
              }}
            >
            <i class="fa-brands fa-linkedin-in"></i>
              <span>About Us</span>
            </div>
            <div
              onClick={() => {
                window.open("https://forms.gle/uqDYGS6HVurXWiTr8", "_blank");
              }}
            >
            <i class="fa-regular fa-comments"></i>
              <span>Feedback</span>
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://chat.whatsapp.com/Bd4Qs5bMBv1BLg8SEfoQy4",
                  "_blank"
                );
              }}
            >
            <i class="fa-solid fa-phone"></i>
              <span>Contact Us</span>
            </div>
          </div>
          <div className="sidebar-footer">
            <p>Powered By leafTech</p>
            <img src={leaf} alt="leaf-icon" />
          </div>
        </div>
      </div>
      <div id="sidebar-mobile">
        <div className={`sidebar-content ${expanded ? "" : "hide"}`}>
          <div className="sidebar-tabs">
            <div className="active-sidebar-tab">
              <i class="fa-solid fa-house"></i>
              <span>Notes & Highlights</span>
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/company/l%C9%98af-technologies/",
                  "_blank"
                );
              }}
            >
              <i class="fa-solid fa-house"></i>
              <span>About Us</span>
            </div>
            <div
              onClick={() => {
                window.open("https://forms.gle/uqDYGS6HVurXWiTr8", "_blank");
              }}
            >
              <i class="fa-solid fa-house"></i>
              <span>Feedback</span>
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://chat.whatsapp.com/Bd4Qs5bMBv1BLg8SEfoQy4",
                  "_blank"
                );
              }}
            >
              <i class="fa-solid fa-house"></i>
              <span>Contact Us</span>
            </div>
          </div>
          <div className="sidebar-footer">
            <p>Powered By leafTech</p>
            <img src={leaf} alt="leaf-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
