import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.png";
import signup from "../../assets/signup.svg";
import leaf from "../../assets/leaf.jpg";
import { Bars } from "react-loader-spinner";
import "./Forgot.css";
import { forgotPasswordStep0 } from "../../store/auth/AuthSlice";
import Navbar from "../../Components/NonAuthPages/Navbar";

const Forgot = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { isLoading, isError, errorMessage, forgotPasswordStep } = useSelector(
    (state) => state.auth
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim() === "") {
      alert("Please enter your email address");
      return;
    }
    // Dispatch action to call API with email
    dispatch(forgotPasswordStep0(email)); // Example action, replace with your actual action
  };

  useEffect(() => {
    if (forgotPasswordStep === 1) {
      setTimeout(() => {
        navigate(`/set-forgot-password?email=${email}`);
      }, 3000);
    }
    else if(forgotPasswordStep === 2){
      navigate('/set-forgot-password')
    }
  },[forgotPasswordStep]);
  return (
    <div className="no-auth-container">
     <Navbar/>

      <section className="no-auth-welcome-section">
        <p>
          <span>Forget Password</span>
          <br />
          Don't worry we have got your back.
        </p>

        <form className="no-auth-form" onSubmit={handleSubmit}>
          <div className="no-auth-input">
            <label htmlFor="forgot-email">Email</label>
            <input
              id="forgot-email"
              type="text"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <button type="submit" className="submit-login">
            {isLoading ? (
              <Bars
                height="25"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{ margin: "0", padding: "0" }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <span>Submit</span>
            )}
          </button>
          {isError ? (
            <p className="no-auth-error">{errorMessage}</p>
          ) : (
            ""
          )}
          {forgotPasswordStep === 1 ? (
            <p className="no-auth-success">
              Email sent successfully.Check you mail box
            </p>
          ) : (
            ""
          )}
        </form>

        <footer className="no-auth-footer">
          <p className="leaf-footer">
            <span>Powered By leafTech</span>
            <img src={leaf} alt="leaf logo" />
          </p>
        </footer>
      </section>
    </div>
  );
};

export default Forgot;
