import React, { useEffect } from "react";
import Cookies from "js-cookie";
import Sidebar from "../../Components/Home/Sidebar/Sidebar";
import Navbar from "../../Components/Home/Navbar/Navbar";
import MainContent from "../../Components/Home/MainContent/MainContent";
import "./Home.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("home loaded");
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]); // Ensure navigate is included as a dependency
  document.title = `BlueNote | Home | ${Cookies.get("user-email")}`;
  return (
    <div className="home">
      <div className="home-left">
        <Sidebar />
      </div>
      <div className="home-right">
        <Navbar />
        <MainContent />
      </div>
    </div>
  );
};

export default Home;
