import AWS from "aws-sdk";
class Authentication {
  /**
   * Create a new Authentication instance.
   * Author: AbhinavVerma
   */
  constructor() {
    throw new Error("Authentication class cannot be instantiated.");
  }

  /**
   * Initiates authentication with AWS Cognito using provided username and password.
   * @param {string} username - The username.
   * @param {string} password - The password.
   */
  static async login(username, password) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });
      console.log(username, password);
      // Initiate authentication using username and password
      const response = await client
        .initiateAuth({
          ClientId: "19gg3abqq3im8nsfa5f49gf48", // Client ID associated with the app
          AuthFlow: "USER_PASSWORD_AUTH", // Using user/password authentication flow
          AuthParameters: {
            USERNAME: username, // Provide the username
            PASSWORD: password, // Provide the password
          },
        })
        .promise();

      // Print all tokens
      console.log("IDToken:", response.AuthenticationResult.IdToken);
      console.log("Access Token:", response.AuthenticationResult.AccessToken);
      console.log("Refresh Token:", response.AuthenticationResult.RefreshToken);
      return {
        success: true,
        IdToken: response.AuthenticationResult.IdToken,
        AccessToken: response.AuthenticationResult.AccessToken,
        RefreshToken: response.AuthenticationResult.RefreshToken,
      };
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: "invalid credentials" };
    }
  }

  /**
   * Extracts the access token from the refresh token.
   * @param {string} refreshToken - The refresh token.
   */
  static async extractAccessTokenFromRefreshToken(refreshToken) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });

      const response = await client
        .initiateAuth({
          ClientId: "19gg3abqq3im8nsfa5f49gf48", // Client ID associated with the app
          AuthFlow: "REFRESH_TOKEN_AUTH", // Using refresh token authentication flow
          AuthParameters: {
            REFRESH_TOKEN: refreshToken, // Provide the refresh token
          },
        })
        .promise();

      console.log(
        "Access Token from Refresh Token:",
        response.AuthenticationResult.AccessToken
      );
      return response.AuthenticationResult.AccessToken;
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  /**
   * Registers a new user with AWS Cognito.
   * @param {string} email - The email address of the user to register.
   * @param {string} password - The password for the new user.
   */
  static async registerUser(email, password) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });

      await client
        .signUp({
          ClientId: "19gg3abqq3im8nsfa5f49gf48", // Client ID associated with the app
          Username: email, // Provide the email address as the username
          Password: password, // Provide the password
        })
        .promise();

      console.log(
        "User registration successful. Please check your email for verification."
      );
      return {
        success: true,
        message:
          "User registration successful. Please check your email for verification.",
      };
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: "failed" , error:error.message};
    }
  }

  /**
   * Verifies the email address of a registered user in AWS Cognito.
   * @param {string} email - The email address of the user to verify.
   * @param {string} confirmationCode - The confirmation code sent to the user's email address.
   */
  static async verifyEmail(email, confirmationCode) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });
      console.log(email, confirmationCode);
      await client
        .confirmSignUp({
          ClientId: "19gg3abqq3im8nsfa5f49gf48", // Client ID associated with the app
          Username: email, // Provide the email address of the user
          ConfirmationCode: confirmationCode, // Provide the confirmation code sent to the user's email
        })
        .promise();

      console.log("Email verification successful.");
      return { success: true, message: "Email verified successful" };
    } catch (error) {
      console.error("Error:", error);
      return { success: false, message: "Email verified failed" };
    }
  }

  /**
   * Initiates forgot password flow for the user.
   * @param {string} username - The username.
   */
  static async forgotPassword(username) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });

      await client
        .forgotPassword({
          ClientId: "19gg3abqq3im8nsfa5f49gf48", // Client ID associated with the app
          Username: username, // Provide the username
        })
        .promise();

      console.log("Forgot password request initiated successfully.");
      return {
        success: true,
        message: "Forgot password request initiated successfully.",
      };
    } catch (error) {
      console.error("Error:", error);
      return {
        success: false,
        message: "Forgot password request failed.",
        error: error.message,
      };
    }
  }

  /**
   * Resets the password for the user using the provided OTP (One Time Password).
   * @param {string} username - The username.
   * @param {string} otp - The One Time Password received from the forgot password flow.
   * @param {string} newPassword - The new password to set.
   */
  static async resetPasswordWithOTP(username, otp, newPassword) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });

      await client
        .confirmForgotPassword({
          ClientId: "19gg3abqq3im8nsfa5f49gf48", // Client ID associated with the app
          Username: username, // Provide the username
          ConfirmationCode: otp, // Provide the OTP received from the forgot password flow
          Password: newPassword, // Provide the new password
        })
        .promise();

      console.log("Password reset successful.");
      return {success:true,message:"Password reset successful."}
    } catch (error) {
      console.error("Error:", error);
      return {success:false,message:"Error in Resetting the password", error:error.message} 
    }
  }
  static async logout(accessToken) {
    try {
      // Initialize Cognito client
      const client = new AWS.CognitoIdentityServiceProvider({
        region: "us-east-1",
      });

      await client
        .globalSignOut({
          AccessToken: accessToken, // Provide the access token to revoke
        })
        .promise();

      console.log("User logged out successfully.");
      return { success: true, message: "User logged out successfully." };
    } catch (error) {
      console.error("Error:", error);
      return {
        success: true,
        message: "User logged out successfully.",
        Error: error,
      };
    }
  }
}

export default Authentication;
