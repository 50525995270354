import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Authentication from "./authentication";
import Cookies from "js-cookie";

const AccessToken = Cookies.get("AccessToken");
const IdToken = Cookies.get("IdToken");
const RefreshToken = Cookies.get("RefreshToken");

const initialState = {
  isLoggedIn: AccessToken === undefined ? false : true,
  isLoading: false,
  AccessToken: AccessToken || null,
  IdToken: IdToken || null,
  RefreshToken: RefreshToken || null,
  isError: true,
  errorMessage: null,
  signupStep: 0,
  emailVerified: false,
  forgotPasswordStep: 0,
};

export const login = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      console.log(userData);
      const response = await Authentication.login(
        userData.email,
        userData.password
      );
      console.log(response);
      if (response.success) {
        Cookies.set("user-email", userData.email);
        return response;
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signup = createAsyncThunk(
  "auth/signup",
  async (userData, thunkAPI) => {
    try {
      const response = await Authentication.registerUser(
        userData.email,
        userData.password
      );
      if (response.success) return response;
      else {
        throw new Error(response.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verify-email",
  async (data, thunkAPI) => {
    try {
      console.log(data);
      const response = await Authentication.verifyEmail(data.email, data.otp);
      console.log(response);
      if (response.success) return response;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message = error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// sending the otp
export const forgotPasswordStep0 = createAsyncThunk(
  "auth/forgot-password-step-0",
  async (email, thunkAPI) => {
    try {
      console.log(email);
      const response = await Authentication.forgotPassword(email);
      console.log(response);
      if (response.success) return response;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// setting the password
export const setForgetPasswordWithOTP = createAsyncThunk(
  "auth/setForgetPasswordWithOTP",
  async (data, thunkAPI) => {
    try {
      const response = await Authentication.resetPasswordWithOTP(
        data.email,
        data.otp,
        data.password
      );
      console.log(response);
      if (response.success) return response;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const Logout = createAsyncThunk("auth/Logout", async (thunkAPI) => {
  try {
    const response = await Authentication.logout(AccessToken);

    if (response.success) return response;
    else {
      throw new Error(response.error.error);
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setErrorNull: (state) => {
      state.isError = false;
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true; // Ensure this is set
        state.AccessToken = action.payload.AccessToken;
        state.IdToken = action.payload.IdToken;
        state.RefreshToken = action.payload.RefreshToken;
        state.user = action.payload.user;
        state.isError = false;
        state.errorMessage = null;
        Cookies.set("IdToken", action.payload.IdToken);
        Cookies.set("AccessToken", action.payload.AccessToken);
        Cookies.set("RefreshToken", action.payload.RefreshToken);
      })

      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.AccessToken = null;
        state.user = null;
        state.isError = true;
        state.errorMessage = "Please enter correct credentials";
      })
      .addCase(signup.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signup.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.isLoading = false;
          state.isLoggedIn = false;
          state.signupStep = 1;
        }
      })
      .addCase(signup.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.AccessToken = null;
        state.user = null;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(verifyEmail.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = null;
        state.emailVerified = true;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = "email verification failed";
      })
      .addCase(Logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.isLoading = false;
        state.user = null;
        state.AccessToken = null;
        state.IdToken = null;
        state.RefreshToken = null;
        Cookies.remove("AccessToken");
        Cookies.remove("IdToken");
        Cookies.remove("RefreshToken");
      })
      .addCase(forgotPasswordStep0.fulfilled, (state) => {
        state.forgotPasswordStep = 1;
        state.isLoading = false;
      })
      .addCase(forgotPasswordStep0.rejected, (state, action) => {
        state.isError = true;
        state.errorMessage = "otp Request is failed, try after some time";
        state.isLoading = false;
      })
      .addCase(forgotPasswordStep0.pending, (state, action) => {
        state.isError = false;
        state.errorMessage = null;
        state.isLoading = true;
      })
      .addCase(setForgetPasswordWithOTP.fulfilled, (state, action) => {
        state.isError = false;
        state.errorMessage = null;
        state.isLoading = false;
        state.forgotPasswordStep = 2;
      })
      .addCase(setForgetPasswordWithOTP.rejected, (state) => {
        state.isError = true;
        state.errorMessage = "Password Reset Failed, Try Again";
        state.isLoading = false;
      })
      .addCase(setForgetPasswordWithOTP.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

export const { reset, setErrorNull } = authSlice.actions;
export default authSlice.reducer;
