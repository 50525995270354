import axios from "axios";

const backendUrl = "https://52.91.8.30";

function convertApiResponse(apiResponse) {

  const mapColor = (color) => {
    switch (color) {
      case "rgb(68, 255, 147)":
        return "rgb(45, 154, 43)"; //green
      case "rgb(66, 229, 255)":
        return "rgb(62, 179, 229)"; //sly blue
      case "rgb(251, 207, 55)":
        return "yellow"; //yellow
      default:
        return color;
    }
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const seconds = date.getSeconds();

    // Determine the suffix for the day
    const suffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Return the formatted date with <$> tag around the suffix
    return `${day}<sup>${suffix(
      day
    )}</sup> ${month} ${year} - ${hour}:${minute}:${seconds}`;
  }

  // Convert the API response to the desired format
  return Object.values(apiResponse)
    .filter(
      (item) =>
        item.color && item.color.trim() !== "" && item.color !== "inherit"
    )
    .map((item) => ({
      title: item.title || "My Note", // Default title if title is null or empty
      thumbnailLink:
        !item.faviconURL || item.faviconURL.trim() === ""
          ? "not-available"
          : item.faviconURL,
      webpage: item.href,
      highlight: item.string,
      notes: item.note || item.string, // If note is null or empty, use string as the note
      date: formatDate(item.time),
      color: mapColor(item.color),
    }));
}
export const getNotesService = async (
  accesstoken,
  workspaceId,
  skip,
  limit
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accesstoken}`,
      },
    };
    const response = await axios.get(
      `${backendUrl}/notes/${workspaceId}?skip=${skip}&limit=${limit}`,
      config
    );
    const data = convertApiResponse(response.data);
    return { success: true, notes: data };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: "Fetching notes failed",
      error: err.message,
    };
  }
};
export const searchNotesService = async (workspaceId, accesstoken, query) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accesstoken}`,
      },
    };
    console.log(workspaceId, accesstoken, query);
    const response = await axios.get(
      `${backendUrl}/searchNotes/${workspaceId}?query=${query}`,
      config
    );
    const data = convertApiResponse(response.data);
    return { success: true, notes: data };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: "Fetching notes failed",
      error: err.message,
    };
  }
};
export const exportPdfService = async (workspaceId) => {
  try {
    await axios.get(`${backendUrl}/pdfReportGen/${workspaceId}`);
    return { success: true, message: "PDF exported" };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: "PDF download failed",
      error: err.message,
    };
  }
};

export const getTotalNotesService = async (accesstoken, workspaceId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accesstoken}`,
      },
    };
    const response = await axios.get(
      `${backendUrl}/totalNotes/${workspaceId}`,
      config
    );
    return { success: true, response: response.data };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: "Fetching total notes count failed",
      error: err.message,
    };
  }
};
