import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./MainContent.css";
import parse from "html-react-parser";
import { getNotes, resetNotes } from "../../../store/home/homeSlice";
import { DNA, Vortex } from "react-loader-spinner";

// Lazy load the NoteCard component
const NoteCard = React.lazy(() => import("./NoteCard"));

const MainContent = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.home.notesList);
  const loading = useSelector((state) => state.home.isLoading);
  const hasMore = useSelector((state) => state.home.hasMore);
  const AccessToken = useSelector((state) => state.auth.AccessToken);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    // Reset notes when the component mounts
    dispatch(resetNotes());
    fetchNotes();
  }, [AccessToken, dispatch]);

  useEffect(() => {
    if (!isFetching) return;
    if (hasMore) {
      fetchNotes();
    }
  }, [isFetching]);

  const fetchNotes = () => {
    const payload = {
      accesstoken: AccessToken,
      workspaceId: 1,
      skip: data.length,
      limit: 10,
    };
    dispatch(getNotes(payload)).then(() => {
      setIsFetching(false);
    });
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading
    ) {
      return;
    }
    setIsFetching(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Function to group notes by date
  const groupByDate = (data) => {
    const grouped = {};
    data.forEach((item) => {
      const date = item.date.split("-")[0]; // Extract date without time
      if (grouped[date]) {
        grouped[date].push(item);
      } else {
        grouped[date] = [item];
      }
    });
    return grouped;
  };

  // Group and sort notes by date
  const groupedData = groupByDate(data);
  const sortedDates = Object.keys(groupedData).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  if ( loading) {
    return (
      <div className="center-screen main-content-loader">
        <Vortex
          visible={true}
          height="80"
          width="80"
          ariaLabel="vortex-loading"
          wrapperStyle={{}}
          wrapperClass="vortex-wrapper"
          colors={["#4fd1c5", "#5d8d88", "#00A2ED", "#00ffe7", "#555", "#326a65"]}
        />
        <div className="loading-text">Loading....</div>
      </div>
    );
  }
  if (sortedDates.length === 0) {
    return <div className="center-screen message">Nothing to show</div>;
  }

  return (
    <div className="main-content-container">
      {sortedDates.map((date, index) => (
        <div key={index}>
          <p>
            &nbsp; Notes & Highlights of{" "}
            <strong>{parse(date.split("-")[0])}</strong>
          </p>
          <div className="note-card-container">
            {/* Wrap the NoteCard component in Suspense with a fallback */}
            <Suspense fallback={<div>Loading...</div>}>
              {groupedData[date].map((elem, index) => (
                <NoteCard data={elem} key={index} />
              ))}
            </Suspense>
            <hr style={{ margin: "20px 0", borderTop: "1px solid #ccc" }} />
          </div>
        </div>
      ))}
      {loading && <div>Loading more notes...</div>}
    </div>
  );
};

export default MainContent;
