import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo.png";
import done from "../../assets/Done.png";
import "./Signup.css/.";
import signuplogo from "../../assets/signup.svg";
import { Link, useNavigate } from "react-router-dom";
import leaf from "../../assets/leaf.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setErrorNull, signup } from "../../store/auth/AuthSlice";
import Navbar from "../../Components/NonAuthPages/Navbar";
import { Bars } from "react-loader-spinner";

const validateEmail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
};

const isStrongPassword = (password) => {
  let checks = [];

  // Check if password is at least 8 characters long
  if (password.length >= 8) checks.push(1);

  // Check if password has at least 1 uppercase character
  if (/[A-Z]/.test(password)) checks.push(2);

  // Check if password has at least 1 lowercase character
  if (/[a-z]/.test(password)) checks.push(3);

  // Check if password has at least 1 symbol
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) checks.push(4);

  // Check if password has at least 1 digit
  if (/\d/.test(password)) checks.push(5);
  return checks;
};
const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn, isError, errorMessage, isLoading } = useSelector(
    (state) => state.auth
  );

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    cPassword: "",
  });

  const [validate, setValidate] = useState({
    email: false,
    password: [],
    cPassword: false,
  });

  const { signupStep } = useSelector((state) => state.auth);

  const handleOnChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };
  useEffect(() => {
    setValidate({
      email: validateEmail(inputs.email),
      password: isStrongPassword(inputs.password),
      cPassword:
        isStrongPassword(inputs.password).length === 5 &&
        inputs.password == inputs.cPassword,
    });
  }, [inputs]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signup({ email: inputs.email, password: inputs.password }));
  };

  useEffect(() => {
    if (signupStep === 1) {
      navigate(`/Verify-Account?email=${inputs.email}`);
    }
  }, [signupStep]);

  useEffect(() => {
    dispatch(setErrorNull());
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);
  document.title = "BlueNote | Signup";
  return (
    <div className="no-auth-container">
      <Navbar page={"signup"} />

      <section className="no-auth-welcome-section">
        <p>
          <span>Hello there!</span>
          <br />
          We are glad to have you onboard. Kindly enter details.
        </p>

        <form onSubmit={handleSubmit} className="no-auth-form">
          <div className="no-auth-input">
            <label for="signup-email">Email</label>
            <input
              id="signup-email"
              type="text"
              name="email"
              onChange={handleOnChange}
              value={inputs.email}
              placeholder="Enter your email"
            />
            <img
              style={{
                display: `${validate.email ? "block" : "none"}`,
              }}
              alt="check"
              id="signup-email-check"
              src={done}
            />
          </div>

          <div className="no-auth-input">
            <label for="signup-password">Password</label>
            <input
              id="signup-password"
              type="password"
              name="password"
              onChange={handleOnChange}
              value={inputs.password}
              placeholder="Enter your email"
            />
            <img
              style={{
                display: `${validate.password.length === 5 ? "block" : "none"}`,
              }}
              alt="check"
              id="signup-password-check"
              src={done}
            />
            <ul
              style={{
                display: `${
                  validate.password.length === 5 ||
                  inputs.password.trim() === ""
                    ? "none"
                    : "block"
                }`,
              }}
            >
              <li
                style={{
                  color: `${
                    validate.password.includes(1) ? "green" : "#E92020"
                  }`,
                }}
              >
                Atleast 8 characters long
              </li>
              <li
                style={{
                  color: `${
                    validate.password.includes(2) ? "green" : "#E92020"
                  }`,
                }}
              >
                Atleast 1 Uppercase Character
              </li>
              <li
                style={{
                  color: `${
                    validate.password.includes(3) ? "green" : "#E92020"
                  }`,
                }}
              >
                Atleast 1 Lowercase Character
              </li>
              <li
                style={{
                  color: `${
                    validate.password.includes(4) ? "green" : "#E92020"
                  }`,
                }}
              >
                Atleast 1 symbol
              </li>
              <li
                style={{
                  color: `${
                    validate.password.includes(5) ? "green" : "#E92020"
                  }`,
                }}
              >
                Atleast 1 digit
              </li>
            </ul>
          </div>

          <div className="no-auth-input">
            <label for="signup-confirm-password">Confirm Password</label>
            <input
              id="signup-confirm-password"
              type="password"
              placeholder="Enter your email"
              name="cPassword"
              value={inputs.cPassword}
              onChange={handleOnChange}
            />
            <img
              style={{
                display: `${validate.cPassword ? "block" : "none"}`,
              }}
              alt="check"
              id="signup-confirm-password-check"
              src={done}
            />
          </div>

          <button
            disabled={!(validate.cPassword && validate.email)}
            type="submit"
            className={`submit-login ${
              validate.cPassword && validate.email
                ? ""
                : "disabled-signup-button"
            }`}
          >
            {isLoading ? (
              <Bars
                height="25"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{ margin: "0", padding: "0" }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <span>Submit</span>
            )}
          </button>
          {isError ? <p className="no-auth-error">{errorMessage}</p> : null}
        </form>

        <footer className="no-auth-footer">
          <span>Already have an account? &nbsp;</span>
          <Link to="/login" className="sign-in-link">
            Sign in
          </Link>

          <p className="leaf-footer">
            <span>Powered By leafTech</span>
            <img src={leaf} alt="leaf logo" />
          </p>
        </footer>
      </section>
    </div>
  );
};

export default Signup;
