import React from "react";
import logo from "../../assets/Logo.png";
import signup from "../../assets/signup.svg";
import { useNavigate } from "react-router-dom";
import "./No-auth-nav.css";

const Navbar = ({ page }) => {
  const navigate = useNavigate();
  return (
    <header className="no-auth-header">
      <div className="no-auth-logo">
        <img src={logo} alt="logo" />
        <span>BlueNote</span>
      </div>

      <div className="buttons">
        <button
          className={`${page === "signup" ? "active-tab" : ""}`}
          disabled={page==='signup'}
          onClick={() => {
            navigate("/signup");
          }}
        >
          <img src={signup} alt="signup logo" /> <span>SIGN UP</span>
        </button>
        <button
          className={`${page === "login" ? "active-tab" : ""}`}
          disabled={page==='login'}
          onClick={() => {
            navigate("/login");
          }}
        >
          <img src={signup} alt="signup logo" /> <span>SIGN IN</span>
        </button>
      </div>

      <button className="no-auth-download-button">
        Download Free Chrome Extension of BlueNote
      </button>
    </header>
  );
};

export default Navbar;
