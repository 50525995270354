import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  exportPdfService,
  getNotesService,
  getTotalNotesService,
  searchNotesService,
} from "./homeService";

const initialState = {
  isSideBarExpanded: false,
  notesList: [],
  isLoading: false,
  totalNotes: 10000,
  skip: 0,
  limit: 10,
  hasMore: true,
  error: null,
  pdfExporting: false,
};

// Thunk to fetch notes with pagination
export const getNotes = createAsyncThunk(
  "home/getNotes",
  async (payload, thunkAPI) => {
    try {
      const email = Cookies.get('user-email')
      const response = await getNotesService(
        payload.accesstoken,
        'Personal-vermaabhinav363@gmail.com',
        // `Personal-${email}`, 
        payload.skip,
        payload.limit
      );
      console.log("home slice got response => ", response);
      if (response.success) return response.notes;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const searchNotes = createAsyncThunk(
  "home/searchNotes",
  async (payload, thunkAPI) => {
    try {
      const email = Cookies.get('user-email')
      const response = await searchNotesService(
      //  `Personal-${email}`, 
      'Personal-vermaabhinav363@gmail.com',
        payload.accesstoken,
        payload.query
      );
      console.log("home slice got response => ", response);
      if (response.success) return response.notes;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Thunk to fetch total number of notes
export const getTotalNotes = createAsyncThunk(
  "home/getTotalNotes",
  async (payload, thunkAPI) => {
    try {
      const response = await getTotalNotesService(
        payload.accesstoken,
        payload.workspaceId
      );
      if (response.success) return response.response.data.totalNotes;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Thunk to fetch total number of notes
export const exportPdf = createAsyncThunk(
  "home/export-pdf",
  async (payload, thunkAPI) => {
    try {
      const email = Cookies.get('user-email')
      const response = await exportPdfService(
        `Personal-${email}`
      );
      if (response.success) return response.response.data.totalNotes;
      else {
        throw new Error(response.error.error);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    toggleExpand: (state) => {
      state.isSideBarExpanded = !state.isSideBarExpanded;
    },
    resetNotes: (state) => {
      state.notesList = [];
      state.skip = 0;
      state.hasMore = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      console.log("action = ", action);
      
      state.notesList = [...state.notesList, ...action.payload];
      state.skip += action.payload.length;
      state.hasMore = state.skip < state.totalNotes;
      state.isLoading = false;
    });
    builder.addCase(getNotes.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getTotalNotes.fulfilled, (state, action) => {
      state.totalNotes = action.payload;
    });
    builder.addCase(getTotalNotes.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(searchNotes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(searchNotes.fulfilled, (state, action) => {
      state.notesList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(searchNotes.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
    builder.addCase(exportPdf.pending, (state, action) => {
      state.pdfExporting = true;
    });
    builder.addCase(exportPdf.fulfilled, (state, action) => {
      state.pdfExporting = false;
    });
    builder.addCase(exportPdf.rejected, (state, action) => {
      state.pdfExporting = false;
    });
  },
});

export const { toggleExpand, resetNotes } = homeSlice.actions;
export default homeSlice.reducer;
