import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/Logo.png";
import done from "../../assets/Done.png";
import "./SetForgotPassword.css";
import signup from "../../assets/signup.svg";
import leaf from "../../assets/leaf.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setForgetPasswordWithOTP } from "../../store/auth/AuthSlice";
import { Bars } from "react-loader-spinner";
import Navbar from "../../Components/NonAuthPages/Navbar";

const validatePassword = (password) => {
  let checks = [];

  if (password.length >= 8) checks.push(1);
  if (/[A-Z]/.test(password)) checks.push(2);
  if (/[a-z]/.test(password)) checks.push(3);
  if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) checks.push(4);
  if (/\d/.test(password)) checks.push(5);

  return checks;
};

const SetForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isError, errorMessage, isLoading, forgotPasswordStep } = useSelector(
    (state) => state.auth
  );
  const [inputs, setInputs] = useState({
    otp: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const [validateInputs, setValidateInputs] = useState({
    otp: false,
    password: [],
    cPassword: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleInput = (event) => {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, "");
  };

  useEffect(() => {
    setValidateInputs({
      otp: inputs.otp.length === 6,
      password: validatePassword(inputs.newPassword),
      cPassword:
        inputs.newPassword.trim() !== "" &&
        inputs.confirmPassword === inputs.newPassword,
    });
  }, [inputs]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs.otp) {
      alert("OTP must be 6 characters long.");
      return;
    }

    if (!validateInputs.password) {
      alert("Password must meet the criteria.");
      return;
    }

    if (!validateInputs.cPassword) {
      alert("Passwords do not match.");
      return;
    }

    dispatch(
      setForgetPasswordWithOTP({
        email: email,
        otp: inputs.otp,
        password: inputs.confirmPassword,
      })
    );
  };

  useEffect(() => {
    if (forgotPasswordStep === 2) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else if (forgotPasswordStep === 0) {
      navigate("/Forgot-Password");
    }
  }, [forgotPasswordStep]);

  return (
    <div className="no-auth-container">
      <Navbar/>

      <section className="no-auth-welcome-section">
        <p>
          <span>Set Your New Password</span>
          <br />
          Don't worry we have got your back.
        </p>

        <form className="no-auth-form" onSubmit={handleSubmit}>
          <div className="no-auth-input">
            <label htmlFor="setpassword-otp">OTP</label>
            <input
              id="setpassword-otp"
              type="text"
              name="otp"
              value={inputs.otp}
              onInput={handleInput}
              onChange={handleInputChange}
              placeholder="Enter your OTP"
              maxLength={6}
            />
          </div>

          <div className="no-auth-input">
            <label htmlFor="setpassword-new-password">New Password</label>
            <input
              id="setpassword-new-password"
              type="password"
              name="newPassword"
              value={inputs.newPassword}
              onChange={handleInputChange}
              placeholder="Enter your new password"
            />
            <img
              style={{
                display: `${
                  validateInputs.password.length === 5 ? "block" : "none"
                }`,
              }}
              alt="check"
              id="set-password-password-check"
              src={done}
            />
            <ul
              style={{
                display: `${
                  validateInputs.password.length !== 5 &&
                  inputs.newPassword.trim() !== ""
                    ? "block"
                    : "none"
                }`,
              }}
            >
              <li
                style={{
                  color: validateInputs.password.includes(1)
                    ? "green"
                    : "#E92020",
                }}
              >
                Atleast 8 characters long
              </li>
              <li
                style={{
                  color: validateInputs.password.includes(2)
                    ? "green"
                    : "#E92020",
                }}
              >
                Atleast 1 Uppercase Character
              </li>
              <li
                style={{
                  color: validateInputs.password.includes(3)
                    ? "green"
                    : "#E92020",
                }}
              >
                Atleast 1 Lowercase Character
              </li>
              <li
                style={{
                  color: validateInputs.password.includes(4)
                    ? "green"
                    : "#E92020",
                }}
              >
                Atleast 1 symbol
              </li>
              <li
                style={{
                  color: validateInputs.password.includes(5)
                    ? "green"
                    : "#E92020",
                }}
              >
                Atleast 1 digit
              </li>
            </ul>
          </div>

          <div className="no-auth-input">
            <label htmlFor="setpassword-confirm-password">
              Confirm Password
            </label>
            <input
              id="setpassword-confirm-password"
              type="password"
              name="confirmPassword"
              value={inputs.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm your password"
            />
            <img
              style={{
                display: `${validateInputs.cPassword ? "block" : "none"}`,
              }}
              alt="check"
              id="set-password-password-check"
              src={done}
            />
          </div>

          <button type="submit" className="submit-login">
            {isLoading ? (
              <Bars
                height="25"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{ margin: "0", padding: "0" }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <span>Submit</span>
            )}
          </button>
          {isError ? (
            <p className="no-auth-error">{errorMessage}</p>
          ) : (
            ""
          )}
          {forgotPasswordStep === 2 ? (
            <p className="no-auth-success">
               Password Set Successfully!
            </p>
          ) : (
            ""
          )}
        </form>

        <footer className="no-auth-footer">
          <p className="leaf-footer">
            <span>Powered By leafTech</span>
            <img src={leaf} alt="leaf logo" />
          </p>
        </footer>
      </section>
    </div>
  );
};

export default SetForgotPassword;
