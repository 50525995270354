import React, { useEffect, useState } from "react";
import "./Verify.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import leaf from "../../assets/leaf.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setErrorNull, verifyEmail } from "../../store/auth/AuthSlice";
import Navbar from "../../Components/NonAuthPages/Navbar";
import { Bars } from "react-loader-spinner";

const Verify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {isLoading} = useSelector((state)=>state.auth)

  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const { isError, errorMessage, emailVerified } = useSelector(
    (state) => state.auth
  );

  const [otp, setOtp] = useState("");

  const handleInput = (event) => {
    const input = event.target;
    input.value = input.value.replace(/[^0-9]/g, "");
  };

  const handleOnChange = (e) => {
    setOtp(e.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(verifyEmail({ email: email, otp: otp }));
  };

  useEffect(() => {
    dispatch(setErrorNull());
  }, [dispatch]);

  useEffect(() => {
    if (emailVerified) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  }, [emailVerified, navigate]);
  document.title = "BlueNote | Verify Account";
  return (
    <div className="no-auth-container">
    <Navbar/>
      <section className="no-auth-welcome-section">
        <p>
          <span>Verify Account</span>
          <br />
          Please enter the OTP received on your registered email.
        </p>

        <form className="no-auth-form" onSubmit={handleSubmit}>
          <div className="no-auth-input">
            <label htmlFor="verify-otp">Enter OTP</label>
            <input
              id="verify-otp"
              type="text"
              value={otp}
              onChange={handleOnChange}
              placeholder="Enter otp"
              onInput={handleInput}
              maxLength={6}
            />
          </div>

          <button type="submit" className="submit-login">
          {isLoading ? (
            <Bars
              height="25"
              width="80"
              color="#fff"
              ariaLabel="bars-loading"
              wrapperStyle={{ margin: "0", padding: "0" }}
              wrapperClass=""
              visible={true}
            />
          ) : (
            <span>Enter</span>
          )}
          </button>

          {emailVerified && (
            <p className="no-auth-user-successful">
              User registered successfully!
            </p>
          )}
          {isError && <p className="no-auth-user-error">{errorMessage}</p>}
        </form>

        <footer className="no-auth-footer">
          <p className="leaf-footer">
            <span>Powered By leafTech</span>
            <img src={leaf} alt="leaf logo" />
          </p>
        </footer>
      </section>
    </div>
  );
};

export default Verify;
