import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login, setErrorNull } from "../../store/auth/AuthSlice";
import Navbar from "../../Components/NonAuthPages/Navbar";
import done from "../../assets/Done.png";
import leaf from "../../assets/leaf.jpg";
import "./Login.css";
import { Bars } from "react-loader-spinner";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError, errorMessage, isLoggedIn, isLoading } = useSelector(
    (state) => state.auth
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    // Set error to null when the component mounts
    dispatch(setErrorNull());
  }, [dispatch]); // Ensure this only runs once on mount

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]); // Only runs when isLoggedIn changes
  document.title = "BlueNote | Login";
  return (
    <div className="no-auth-container">
      <Navbar page={"login"} />
      <section className="no-auth-welcome-section">
        <p>
          <span>Hello there!</span>
          <br />
          We are glad to have you onboard. Kindly enter details.
        </p>
        <form className="no-auth-form" onSubmit={handleSubmit}>
          <div className="no-auth-input">
            <label htmlFor="login-email">Email</label>
            <input
              id="login-email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <img alt="check" id="login-email-check" src={done} />
          </div>
          <div className="no-auth-input">
            <label htmlFor="login-password">Password</label>
            <input
              id="login-password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={handlePasswordChange}
            />
            <img alt="check" id="login-password-check" src={done} />
          </div>
          <button type="submit" className="submit-login">
            {isLoading ? (
              <Bars
                height="25"
                width="80"
                color="#fff"
                ariaLabel="bars-loading"
                wrapperStyle={{ margin: "0", padding: "0" }}
                wrapperClass=""
                visible={true}
              />
            ) : (
              <span>Submit</span>
            )}
          </button>
          {isError && <p className="no-auth-error"> {errorMessage} </p>}
        </form>
        <footer className="no-auth-footer">
          <Link to="/Forgot-Password" className="forgot-link">
            Forgot Password <br />
          </Link>
          <span className="no-auth-dont-have-account">
            Don't have an account?&nbsp;
          </span>
          <Link to="/Signup" className="log-in-link">
            Sign up{" "}
          </Link>
          <p className="leaf-footer">
            <span>Powered By leafTech</span>
            <img src={leaf} alt="leaf logo" />
          </p>
        </footer>
      </section>
    </div>
  );
};

export default Login;
